

































import MNotificationVue from "@/mixins/MNotification.vue";
import { MAXIMUM_FILE_SIZE } from "@/models/constant/global.constant";
import { ResponseUploadData } from "@/models/interface/assets.interface";
import { hasInvalidFilename } from "@/validator/globalvalidator";
import { fileServices } from "@service/file.service";
import Vue from "vue";

export default Vue.extend({
  name: "CUpload",
  mixins: [
    MNotificationVue,
  ],
  props: {
    accept: {
      type: String,
      default: ".pdf, .png, .jpg, .jpeg, .xlsx"
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: {
        upload: false,
        delete: false,
      },
      uploadedFile: "",
    };
  },
  methods: {
    isReachMaxSize(size: number): boolean {
      return size >= MAXIMUM_FILE_SIZE;
    },
    emitSuccess(res: ResponseUploadData): void {
      this.$emit("success", res);
    },
    emitDelete(): void {
      this.$emit("deleted");
    },
    removeFile(fileName: string): Promise<boolean> {
      return fileServices.deleteFile(fileName);
    },
    async handleRemove(): Promise<void> {
      try {
        this.loading.delete = true;
        await this.removeFile(this.uploadedFile);
        this.showNotifSuccess("notif_file_delete_success");
        this.emitDelete();
        this.uploadedFile = "";
      } catch (error) {
        this.showNotifError("notif_file_delete_fail");
      } finally {
        this.loading.delete = false;
      }
    },
    async uploadFile({file}: {file: File}): Promise<void> {

      if (this.isReachMaxSize(file.size)) {
        this.showNotifError("notif_file_upload_validation_1");
        return;
      }

      if (hasInvalidFilename(file.name)) {
        this.showNotifError("notif_file_upload_validation_2");
        return;
      }

      const fd = new FormData();
      fd.append("file", file);

      try {
        this.loading.upload = true;

        const res = await fileServices.uploadFile(fd);
        this.emitSuccess(res);
        this.uploadedFile = res.objectName;
        
        this.showNotifSuccess("notif_file_upload_successfully", {filename: file.name});
      } catch (error) {
        this.showNotifError("notif_file_upload_failed", {filename: file.name});
      } finally {
        this.loading.upload = false;
      }

    }
  }
});
