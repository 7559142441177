var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.uploadedFile
        ? _c(
            "a-upload",
            {
              attrs: {
                name: "file",
                multiple: _vm.multiple,
                "show-upload-list": false,
                accept: _vm.accept,
                "custom-request": _vm.uploadFile
              }
            },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    icon: "upload",
                    loading: _vm.loading.upload,
                    disabled: _vm.disabled
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_upload")) + " ")]
              )
            ],
            1
          )
        : [
            _c(
              "a-space",
              [
                _c("span", [_vm._v(_vm._s(_vm.uploadedFile))]),
                _c("a-button", {
                  attrs: {
                    icon: "delete",
                    size: "small",
                    type: "danger",
                    loading: _vm.loading.delete
                  },
                  on: { click: _vm.handleRemove }
                })
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }